<template>
    <div class="card_main">
        <div class="header items-center"
             :class="isExpand && 'border-b'">
            <div class="number">
                {{ assessment.issue.number }}
            </div>
            <div class="total_value">
                <div class="circle" :style="{ backgroundColor: circleColor }">
                    <span>{{ assessment.total_value }}</span>
                </div>
            </div>
            <div class="organization">
                <span class="break-all">
                    {{ assessment?.organization?.name }}
                </span>
            </div>
            <div class="issue_date">
                <span>{{ this.$moment(assessment.issue.issue_date).format('DD MMMM YYYY') }}</span>
            </div>
            <div class="issue_status">
                <a-tag :color="assessment.status.color">
                    {{ assessment.status.name }}
                </a-tag>
            </div>
            <div class="delete_button">
                <a-button
                    v-if="deleteAvailability"
                    type="ui"
                    shape="circle"
                    @click="deleteAssessment()"
                    ghost
                    class="flex items-center justify-center">
                    <i class="fi fi-rr-trash text-red-500"></i>
                </a-button>
            </div>
            <div class="edit_button">
                <a-button
                    v-if="editAvailability"
                    type="ui" 
                    shape="circle"
                    @click="editAssessment()"
                    ghost
                    class="flex items-center justify-center">
                    <i class="fi fi-rr-edit"></i>
                </a-button>
            </div>
            <div 
                class="button"
                :class="isExpand && 'rotate-90'"
                @click="openAssessmentis()">
                <a-button
                    type="link">
                    <i class="fi fi-rr-angle-right"></i>
                </a-button>
            </div>
        </div>
        <a-spin :spinning="assessmentLoading">
            <div class="item_body " v-show="isExpand">
                <template v-if="assessmentDetail">
                    <div class="inquir_info mb-5">
                        <div class="summary">
                            <div>
                                <span class="font-semibold">{{ $t('inquiries.issue_category') }}: </span>
                                <span>{{ assessmentDetail?.issue?.summary }}</span>
                            </div>
                            <div class="mt-5">
                                <span class="font-semibold">{{ $t('inquiries.sent_to') }}: </span>
                                <span>{{ getRecipient }}</span>
                            </div>
                            <div class="inquir_text mt-4">
                                <div class="font-semibold mb-1">{{ $t('inquiries.inquiry_text') }}:</div>
                                <template v-if="assessmentDetail?.issue?.text">
                                    <TextViewer
                                        :body="assessmentDetail?.issue?.text"/>
                                </template>
                                <template v-else>
                                    <div class="no-data">{{ $t('inquiries.no_data') }}</div>
                                </template>
                            </div>
                        </div>
                        <div class="author">
                            <a-button type="primary" ghost class="mb-2" @click="visible = true">
                                {{ $t('inquiries.history_changes') }}
                            </a-button>
                            <div class="font-semibold">{{ $t('inquiries.initial_risk_assessment_by') }}: </div>
                            <Profiler class="pt-2" :user="assessmentDetail?.author" />
                        </div>
                        <div class="criterions">
                            <div class="font-semibold mb-3">{{ $t('inquiries.assessment_criteria') }}:</div>
                            <div v-for="item in assessmentDetail.risk_assessment_criteria" :key="item.criteria.id" class="risk_assessment_criteria_list">
                                <div :class="item.value === 1 && 'font-semibold'">
                                    {{ item.criteria.name }}
                                </div>
                                <div :class="item.value === 1 && 'font-semibold'">
                                    {{ item.value === 1 ? $t('inquiries.yes') : $t('inquiries.no') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="empty">
                    <a-empty :description="false" />
                </template>
            </div>
        </a-spin>
        <a-drawer
            :title="$t('inquiries.history_changes')"
            placement="right"
            :width="isMobile ? '100%' : 800"
            :visible="visible"
            @close="visible = false">
            <History 
                v-if="visible"
                :related_object="assessment.id" 
                injectContainer
                filterPrefix="risk_assessment"
                modelLabel="risk_assessment.RiskAssessmentModel" />
        </a-drawer>
    </div>
</template>
  
<script>
import TextViewer from '@apps/CKEditor/TextViewer.vue'
import eventBus from '@/utils/eventBus'
import History from '@apps/History/index.vue'

export default {
    name: 'RiskAssessment',
    components: {
        TextViewer,
        History
    },
    props: {
        assessment: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            isExpand: false,
            assessmentLoading: false,
            assessmentDetail: null,
            empty: false,
            visible: false
        };
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        },
        circleColor() {
            if (this.assessment.total_value >= 1 && this.assessment.total_value <= 2) {
                return 'yellow'
            } else if (this.assessment.total_value >= 3 && this.assessment.total_value <= 5) {
                return 'orange'
            } else if (this.assessment.total_value > 5) {
                return 'red'
            } else {
                return 'white'
            }
        },
        getRecipient() {
            const recipient = this.assessmentDetail.sent_for === 1 
                ? this.$t('inquiries.head_of_apparatus') 
                : this.$t('inquiries.main_leader_or_deputies');
            return recipient;
        },
        deleteAvailability() {
            if ('delete' in this.assessment && 'availability' in this.assessment.delete) {
                return this.assessment.delete['availability']
            } else {
                return false
            }
        },
        editAvailability() {
            if ('edit' in this.assessment && 'availability' in this.assessment.edit) {
                return this.assessment.edit['availability']
            } else {
                return false
            }
        }
    },
    created() {
        eventBus.$on('update_assessment_details', id => {
            if (this.assessment.id === id) {
                this.getAssessmentDetail()
            }
        })
    },
    beforeDestroy() {
        eventBus.$off('update_assessment_details')
    },
    methods: {
        async openAssessmentis() {
            this.isExpand = !this.isExpand
            if (this.isExpand) {
                await this.getAssessmentDetail()
            } else {
                this.assessmentDetail = null
            }
        },
        editAssessment() {
            eventBus.$emit('edit_inquir', this.assessment.id)
        },
        deleteAssessment() {
            this.$confirm({
                title: this.$t('inquiries.confirm_delete_title'),
                content: '',
                okText: this.$t('inquiries.delete'),
                okType: 'danger',
                zIndex: 2000,
                closable: true,
                cancelText: this.$t('inquiries.cancel'),
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.post('/table_actions/update_is_active/', {
                            id: this.assessment.id,
                            is_active: false
                        })
                            .then(() => {
                                eventBus.$emit('assessment_list_reload')
                                this.$message.success(this.$t('inquiries.delete_success'))
                                resolve()
                            })
                            .catch(e => {
                                console.log(e)
                                reject(e)
                            })
                    })
                }
            })
        },
        async getAssessmentDetail() {
            if (!this.assessmentLoading) {
                try {
                    this.assessmentLoading = true
                    const { data } = await this.$http.get(`risk_assessment/${this.assessment.id}/`)
                    if (data) {
                        this.assessmentDetail = data
                    }
                } catch (e) {
                    console.log(e)
                    this.empty = true
                } finally {
                    this.assessmentLoading = false
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.card_main {
    margin-bottom: 20px;
    .inquir_info {
        display: grid;
        grid-template-columns: 1fr max-content;
        grid-template-rows: max-content max-content max-content;
        gap: 30px;

        .author{
        }
        
        .inquir_text, .criterions {
            grid-column: span 2;
            .risk_assessment_criteria_list {
                display: grid;
                grid-template-columns: 1fr 40px;
                padding: 2px;
                border-top-width: 1px;
                border-bottom-width: 0px;

            }
        }
    }
    .header {
        background-color: rgb(243 244 246);
        display: grid;
        grid-template-columns: 2.1fr 0.3fr 10fr 1.7fr 1fr 0.3fr 0.3fr 0.3fr;
        column-gap: 20px;
        .status {
            justify-self: right;
        }
        .total_value {
            padding: 5px;
        }
        .number {
            padding: 5px;
            padding-left: 10px;
            min-width: 140px;
        }
        .organization{
            align-items: center;
        }
        .name {
            font-weight: 600;
        }
        .issue_date {
            min-width: 110px;
        }
        .issue_status {
            min-width: 90px;
        }
        .delete_button {
            min-width: 32px;
        }
        .edit_button {
            min-width: 32px;
        }

    }
    .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        color: black;
    }

}
.theader {
    font-weight: 600;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.custom_border_color {
    border-color: var(--bgColor6);
}
.contractor {
    display: grid;
    grid-template-columns: max-content 1fr;
}
.item_body {
    padding: 15px;
    
}
.file_name {
    margin-left: 10px;
}
.no-data{
    opacity: 0.3;
}
</style>